import { css } from '@emotion/react';

const style = css`
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 123%;
    text-transform: uppercase;
`;

export const h1 = css`
    ${style};
    font-weight: 600;
    font-size: 4rem;

    @media (min-width: 1280px) {
        font-size: 5rem;
    }
`;

export const h2 = css`
    ${style};
    font-size: 3rem;
    letter-spacing: -0.035em;

    @media (min-width: 1280px) {
        font-size: 4rem;
    }
`;

export const h3 = css`
    ${style};
    font-size: 2rem;

    @media (min-width: 1280px) {
        font-size: 3rem;
    }
`;

export const h4 = css`
    ${style};
    font-size: 1.25rem;

    @media (min-width: 1280px) {
        font-size: 1.5rem;
    }
`;

export const smTxt = css`
    font-size: 1rem;

    @media (min-width: 1280px) {
        font-size: 1.125rem;
    }
`;

export const lgTxt = css`
    font-size: 1.25rem;

    @media (min-width: 1280px) {
        font-size: 1.5rem;
    }
`;
