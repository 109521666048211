exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-semaglutide-tsx": () => import("./../../../src/pages/semaglutide.tsx" /* webpackChunkName: "component---src-pages-semaglutide-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-boosting-semaglutide-for-weight-loss-the-benefits-of-b-12-injections-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/t-clinic-semaglutide/t-clinic-semaglutide/src/content/blog/boosting-semaglutide-for-weight-loss-the-benefits-of-b12-injections/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-boosting-semaglutide-for-weight-loss-the-benefits-of-b-12-injections-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-debunking-misconceptions-the-safety-of-compounded-semaglutide-in-your-weight-loss-journey-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/t-clinic-semaglutide/t-clinic-semaglutide/src/content/blog/debunking-misconceptions-the-safety-of-compounded-semaglutide-in-your-weight-loss-journey/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-debunking-misconceptions-the-safety-of-compounded-semaglutide-in-your-weight-loss-journey-index-mdx" */)
}

