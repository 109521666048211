import { h2, smTxt } from '@/layout/styles/Typography';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SecondaryBtn } from './buttons';

export const Form = styled.form<{ submit: boolean }>`
    position: relative;
    max-width: 460px;
    margin: 0 auto;
    scroll-margin-top: 85px;
    padding: 32px 16px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 17px 16px 65px -16px rgba(23, 79, 97, 0.33);
    border-radius: 5px;

    ${({ submit }) =>
        submit &&
        css`
            > *:not(div:last-of-type) {
                pointer-events: none;
                visibility: hidden;
                opacity: 0;
            }

            /* fallback */

            input,
            label,
            select,
            text-area,
            button {
                pointer-events: none;
                visibility: hidden;
                opacity: 0;
            }
        `};

    @media (min-width: 1024px) {
        padding: 32px;
    }
`;

export const Input = styled.input<{ rows?: number; icon?: boolean }>`
    ${smTxt};
    color: ${({ theme }) => theme.colors.dark};
    border: 1px solid #e9eeed;
    width: 100%;
    padding: ${({ icon }) => (icon ? '11px 16px 11px 40px' : '11px 16px')};
    display: block;
    outline: none;
    transition: border-color 0.3s ease-in;
    border-radius: 5px;
    margin-bottom: 24px;
    resize: none;
    background-color: #e9eeed;

    :hover {
        border-color: ${({ theme }) => theme.colors.green};
    }

    :focus-visible {
        border-color: ${({ theme }) => theme.colors.green};
    }

    ::placeholder {
        color: rgba(7, 10, 21, 0.5);
    }

    option {
        text-transform: capitalize;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        text-fill-color: ${({ theme }) => theme.colors.dark};
        color: ${({ theme }) => theme.colors.dark};
        -webkit-box-shadow: 0 0 0px 1000px #e9eeed inset;
        transition: background-color 50000s ease-in-out 0s, border-color 0.3s ease-in;
    }

    :focus:invalid {
        border-color: #d50c39;
    }

    @media (min-width: 1024px) {
        margin-bottom: 36px;
    }
`;

export const Label = styled.label<{ icon?: boolean }>`
    ${smTxt};
    margin-bottom: 8px;
    display: inline-block;
    text-transform: capitalize;
    transition: color 0.3s ease-in-out;

    ${({ icon }) =>
        icon &&
        css`
            position: relative;

            ::before {
                content: '';
                width: 16px;
                height: 16px;
                position: absolute;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                top: 52px;
                left: 16px;
            }
        `};

    :hover {
        color: ${({ theme }) => theme.colors.green};
    }

    @media (min-width: 1280px) {
        ::before {
            top: 56px;
        }
    }
`;

export const CheckBoxes = styled.div`
    margin-bottom: 24px;

    > p {
        margin: 0 0 8px;

        :hover {
            color: ${({ theme }) => theme.colors.dark};
        }
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 16px;
        max-width: max-content;

        > label {
            ${smTxt};
            text-transform: capitalize;
            margin-left: 10px;
            transition: color 0.3s ease-in-out;

            :hover {
                color: ${({ theme }) => theme.colors.green};
            }
        }

        > input {
            width: 24px;
            height: 24px;
            accent-color: ${({ theme }) => theme.colors.green};
            transition: accent-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

            :hover {
                box-shadow: inset 0 0 0 50px rgba(130, 163, 39, 0.5);
            }

            :focus-visible {
                box-shadow: inset 0 0 0 50px rgba(130, 163, 39, 0.5);
            }

            :checked {
                box-shadow: none;
            }
        }
    }

    @media (min-width: 1024px) {
        margin-bottom: 36px;
    }
`;

const StyledThanks = styled.div<{ submit: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: ${({ submit }) => (submit ? 10 : -1)};
    opacity: ${({ submit }) => (submit ? 1 : 0)};
    transform: ${({ submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;

    > h3 {
        ${h2};
        margin-top: 0;
    }

    > p {
        font-size: 1.25rem;
    }

    > button {
        visibility: ${({ submit }) => (submit ? 'visible' : 'hidden')};
    }

    @media (min-width: 768px) {
        padding: 64px;
    }
`;

export const Thanks = ({
    submit,
    setSubmit,
}: {
    submit: boolean;
    setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
    <StyledThanks submit={submit}>
        <h3>Thank you</h3>
        <p>Someone will be right with you shortly!</p>

        <SecondaryBtn onClick={() => setSubmit(false)} aria-hidden={!submit}>
            Resubmit Form
        </SecondaryBtn>
    </StyledThanks>
);
