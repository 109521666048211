import { CLink } from '@/CLink';
import styled from '@emotion/styled';
import { LogoLink } from './Nav';
import { getNavLink } from './NavLinks';
import { flexSpace, flexStart, linkStyles, px, py } from './styles/classes';

const Section = styled.footer`
    ${py};
    ${px};
    border-top: 1px solid rgba(7, 10, 21, 0.5);
    max-width: 1164px;
    box-sizing: content-box;
    margin: 0 auto;
    position: relative;

    ::before,
    ::after {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${({ theme }) => theme.colors.light};
        z-index: -1;
    }

    ::before {
        left: calc((100vw - 100%) / -2);
    }

    ::after {
        right: calc((100vw - 100%) / -2);
    }

    > a:first-of-type {
        margin-bottom: 48px;
    }

    > p:last-of-type {
        font-size: 0.75rem;
        margin: 64px 0 0;
    }
`;

const Links = styled.div`
    ${flexStart};
    flex-direction: column;

    :not(:last-of-type) {
        margin-bottom: 40px;
    }

    > p {
        font-weight: 600;
        margin: 0 0 28px;
    }

    > a {
        ${linkStyles};
        color: rgba(7, 10, 21, 0.7);
        text-transform: capitalize;

        :not(:last-of-type) {
            margin-bottom: 36px;
        }
    }
`;

const Flex = styled.div`
    @media (min-width: 768px) {
        ${flexSpace};
        align-items: flex-start;

        > div {
            width: 520px;
        }
    }
`;

export const Footer = () => {
    return (
        <Section>
            <LogoLink />
            <Flex>
                <Links>
                    <p>Discover</p>

                    <CLink to={getNavLink('semaglutide')}>semaglutide</CLink>

                    <CLink to={getNavLink('contact us')}>contact us now</CLink>

                    <CLink to="/privacy-policy/">privacy policy</CLink>
                </Links>
                <Links>
                    <p>Popular Services</p>
                    <CLink to="https://fortmyerstestosterone.com/testosterone/">Testosterone</CLink>

                    <CLink to={getNavLink('services')}>other services</CLink>
                </Links>
            </Flex>
            <p>Fort Myers Semaglutide LLC © {new Date().getFullYear()}. All rights reserved.</p>
        </Section>
    );
};
