export const siteInfo = {
    email: {
        link: 'mailto:info@fortmyerssemaglutide.com',
        text: 'info@fortmyerssemaglutide.com',
    },
    address: {
        text: '6900 Daniels Pkwy #29 A-336, Fort Myers, FL 33912',
        link: 'https://goo.gl/maps/Ro1PbhGFvvk1xVom6',
    },
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'semaglutide',
        link: '/semaglutide/',
    },
    {
        text: 'other services',
        link: '/services/',
    },
    {
        text: 'blog',
        link: '/blog/',
    },
    {
        text: 'contact us now',
        link: '#contact',
    },
];

const flatLinks = (() => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                flat.push({
                    text: data.text,
                    link: data.link,
                });
                pushFlat(data.links);
            }
        });

    pushFlat(navLinks);
    return flat;
})();

export function getNavLink(text: string) {
    const linkObj = flatLinks.find(data => data.text.toLowerCase() === text.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}
