import { css, Global, Theme } from '@emotion/react';
import '@fontsource/poppins';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import emotionNormalize from 'emotion-normalize';
import { h1, h2, h3, h4 } from './Typography';

export const theme: Theme = {
    colors: {
        white: '#ffffff',
        light: '#DBEFF8',
        dark: '#070A15',
        green: '#82A327',
    },
    spacing: {
        md: '2.22vw', //32px at 1440px wide viewport
        lg: '4.45vw', //64px ^^
        xl: '6.67vw', //96px ^^
        xxl: '8.89vw', //128px ^^
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={() => css`
                ${emotionNormalize};

                html {
                    font-size: 100%;
                    overflow-x: hidden;
                    scroll-behavior: smooth;
                }
                body {
                    color: ${theme.colors.dark};
                    font-family: 'Poppins', sans-serif;
                    font-weight: normal;
                    font-size: 1.125rem;
                    line-height: 170%;
                    word-wrap: break-word;
                    font-kerning: normal;
                    word-wrap: break-word;
                    -webkit-font-smoothing: antialiased;
                    overflow: hidden;
                }

                h1 {
                    ${h1};
                }

                h2 {
                    ${h2};
                }

                h3 {
                    ${h3};
                }

                h4 {
                    ${h4};
                }

                a {
                    text-decoration: none;
                    color: ${theme.colors.dark};
                    outline: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                ul,
                ol {
                    padding-inline-start: 24px;
                }

                @media (min-width: 1280px) {
                    body {
                        font-size: 1.25rem;
                    }
                }
            `}
        />
    );
};
